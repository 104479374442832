import React from 'react';
import { createRoot } from 'react-dom/client';
import { snakeCase } from 'change-case';
import News from '@/src/home/News';
import SeasonTray from '@/src/home/SeasonTray'; 
import ScoreTable from '@/src/admin/ScoreTable';
import Today from '@/src/home/Today';
import VolleyballGameControl from '@/src/admin/VolleyballGameControl';

const rootComponents: {[key: string]: (arg0: any) => React.JSX.Element | null} = {
  news: News,
  season_tray: SeasonTray,
  score_table: ScoreTable,
  today: Today,
  volleyball_game_control: VolleyballGameControl
}

declare global {
  interface Window { reactComponent: any; }
}
window.reactComponent = function(component: string, props: object) {
  const element = document.getElementById(component);
  if (element === null) {
    return;
  }
  const rootDiv = createRoot(element);
  const RootComponent = rootComponents[snakeCase(component)];
  rootDiv.render(<RootComponent {...props} />);
}