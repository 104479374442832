import React, { useState, useEffect } from "react";
import { Game, ScheduleItem, Sport } from "@/types";
import GameScorecard from "@/src/game/GameScorecard";

export default function Today() {
  const [scheduleData, setScheduleData] = useState<ScheduleItem[]>([]);

  useEffect(() => {
    const getData = async (startOfDay: number) => {
      const response = await fetch(`/home/today.json?day_start=${startOfDay}`);
      const data = await response.json();
      setScheduleData(data.games.concat(data.events));
    };

    getData(new Date().setHours(0, 0, 0, 0) / 1000);
  }, []);

  if (scheduleData.length === 0) {
    return null;
  }

  scheduleData.forEach(scheduleItem => {
    if (scheduleItem.time_tba) {
      scheduleItem.day_string = new Intl.DateTimeFormat('en-US', { timeZone: 'America/Chicago', month: "short", day: "numeric" }).format(new Date(scheduleItem.start_time * 1000)).replace(/ /g, '\xa0');
      scheduleItem.time_string = 'TBA';
    } else {
      scheduleItem.day_string = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, month: "short", day: "numeric" }).format(new Date(scheduleItem.start_time * 1000)).replace(/ /g, '\xa0');
      scheduleItem.time_string = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, hour: "numeric", minute: "2-digit" }).format(new Date(scheduleItem.start_time * 1000)).replace(/ /g, '\xa0');
    }

    if (scheduleItem.type === 'game') {
      if (scheduleItem.postponed) {
        scheduleItem.time_string = '';
        scheduleItem.tv_channel = 'Postponed';
      } else if (scheduleItem.state === 'in_progress') {
        scheduleItem.time_string = 'Live';
      } else if (scheduleItem.state === 'finished') {
        scheduleItem.time_string = 'FINAL';
      }
    }
  });

  const todayStr = `${new Date().toLocaleString('default', {month: 'short'})} ${new Date().getDate()}`;

  return (
    <div id="today" className="tray">
      <div className="inner-tray" style={{paddingTop: '3.5em'}}>
        <div className="corner left">
          TODAY
        </div>
        <div className="corner right">
          {todayStr}
        </div>
        {scheduleData.map((scheduleItem) => scheduleItem.type === 'game' ? (
          <GameScorecard game={scheduleItem} today={true} />
        ) : (
        <div key={`team-event-${scheduleItem.id}`} className="today-game">
          <img className="slanted-hero" src={`/images/today/.png`} />

          <div className="header">
            <div>{scheduleItem.name}</div>
            <div className="right">
              {scheduleItem.tv_channel !== null ? (`${scheduleItem.tv_channel} - `) : ''}{scheduleItem.time_string}
            </div>
          </div>
        </div>
        ))}
      </div>
    </div>
  );
}