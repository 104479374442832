export const TEAMS = {
  "A&M Commerce": {
    logo: "texas_a_m_commerce.png",
  },
  "AIC": {
    logo: "american_international.png",
  },
  "Abilene Christian": {
    logo: "abilene_christian.png",
  },
  "Air Force": {
    logo: "air_force.png",
    mascots: "Falcons",
  },
  "Akron": {
    logo: "akron.png",
    mascots: "Zips",
  },
  "Alabama": {
    logo: "alabama.png",
    mascots: "Crimson Tide",
  },
  "Alabama A&M": {
    logo: "alabama_a_m.png",
  },
  "Alabama State": {
    logo: "alabama_state.png",
  },
  "Alabama-Huntsville": {
    logo: "alabama_huntsville.png",
  },
  "Alaska": {
    logo: "alaska.png",
  },
  "Alaska-Anchorage": {
    logo: "alaska_anchorage.png",
  },
  "Albany": {
    logo: "albany.png",
  },
  "Alcorn State": {
    logo: "alcorn_state.png",
  },
  "American": {
    logo: "american.png",
  },
  "Appalachian State": {
    logo: "appalachian_state.png",
    mascots: "Mountaineers",
  },
  "Arizona": {
    logo: "arizona.png",
    mascots: "Wildcats",
  },
  "Arizona State": {
    logo: "arizona_state.png",
  },
  "Arkansas": {
    logo: "arkansas.png",
    mascots: "Razorbacks",
  },
  "Arkansas State": {
    logo: "arkansas_state.png",
  },
  "Arkansas-Pine Bluff": {
    logo: "arkansas_pine_bluff.png",
  },
  "Army": {
    logo: "army.png",
  },
  "Auburn": {
    logo: "auburn.png",
    mascots: "Tigers",
  },
  "Austin Peay": {
    logo: "austin_peay.png",
  },
  "BYU": {
    logo: "brigham_young.png",
    mascots: "Cougars",
  },
  "Ball State": {
    logo: "ball_state.png",
  },
  "Baylor": {
    logo: "baylor.png",
    mascots: "Bears",
  },
  "Bellarmine": {
    logo: "bellarmine.png",
  },
  "Belmont": {
    logo: "belmont.png",
  },
  "Bemidji State": {
    logo: "bemidji_state.png",
  },
  "Bentley": {
    logo: "bentley.png",
  },
  "Bethune-Cookman": {
    logo: "bethune_cookman.png",
  },
  "Binghamton": {
    logo: "binghamton.png",
  },
  "Boise State": {
    logo: "boise_state.png",
  },
  "Boston": {
    logo: "boston.png",
  },
  "Boston College": {
    logo: "boston_college.png",
  },
  "Bowling Green": {
    logo: "bowling_green.png",
  },
  "Bradley": {
    logo: "bradley.png",
    mascots: "Braves",
  },
  "Brooklyn St. Francis": {
    logo: "brooklyn_saint_francis.png",
    mascots: "Terriers",
  },
  "Brown": {
    logo: "brown.png",
    mascots: "Bears",
  },
  "Bryant": {
    logo: "bryant.png",
    mascots: "Bulldogs",
  },
  "Bucknell": {
    logo: "bucknell.png",
    mascots: "Bison",
  },
  "Buffalo": {
    logo: "buffalo.png",
    mascots: "Bulls",
  },
  "Butler": {
    logo: "butler.png",
    mascots: "Bulldogs",
  },
  "CSU Bakersfield": {
    logo: "california_state_bakersfield.png",
    mascots: "Roadrunners",
  },
  "Cal Baptist": {
    logo: "california_baptist.png",
    mascots: "Lancers",
  },
  "Cal Poly": {
    logo: "california_poly.png",
    mascots: "Mustangs",
  },
  "Cal State Fullerton": {
    logo: "california_state_fullerton.png",
    mascots: "Titans",
  },
  "Cal State Northridge": {
    logo: "california_state_northridge.png",
    mascots: "Matadors",
  },
  "California": {
    logo: "california.png",
    mascots: "Golden Bears",
  },
  "Campbell": {
    logo: "campbell.png",
    mascots: "Fighting Camels",
  },
  "Canisius": {
    logo: "canisius.png",
    mascots: "Golden Griffins",
  },
  "Centenary": {
    logo: "centenary.png",
    mascots: "Gentlemen",
  },
  "Central Arkansas": {
    logo: "central_arkansas.png",
    mascots: "Bears",
  },
  "Central Connecticut": {
    logo: "central_connecticut.png",
    mascots: "Blue Devils",
  },
  "Central Michigan": {
    logo: "central_michigan.png",
    mascots: "Chippewas",
  },
  "Charleston": {
    logo: "charleston.png",
    mascots: "Cougars",
  },
  "Charleston Southern": {
    logo: "charleston_southern.png",
    mascots: "Buccaneers",
  },
  "Charlotte": {
    logo: "charlotte.png",
    mascots: "49ers",
  },
  "Chattanooga": {
    logo: "chattanooga.png",
    mascots: "Mocs",
  },
  "Chicago State": {
    logo: "chicago_state.png",
    mascots: "Cougars",
  },
  "Cincinnati": {
    logo: "cincinnati.png",
    mascots: "Bearcats",
  },
  "Clarkson": {
    logo: "clarkson.png",
    mascots: "Golden Knights",
  },
  "Clemson": {
    logo: "clemson.png",
    mascots: "Tigers",
  },
  "Cleveland State": {
    logo: "cleveland_state.png",
    mascots: "Vikings",
  },
  "Coastal Carolina": {
    logo: "coastal_carolina.png",
    mascots: "Chanticleers",
  },
  "Colgate": {
    logo: "colgate.png",
    mascots: "Raiders",
  },
  "Colorado": {
    logo: "colorado.png",
    mascots: "Buffaloes",
  },
  "Colorado College": {
    logo: "colorado_college.png",
    mascots: "Tigers",
  },
  "Colorado State": {
    logo: "colorado_state.png",
    mascots: "Rams",
  },
  "Columbia": {
    logo: "columbia.png",
    mascots: "Lions",
  },
  "Coppin State": {
    logo: "coppin_state.png",
    mascots: "Eagles",
  },
  "Cornell": {
    logo: "cornell.png",
    mascots: "Big Red",
  },
  "Creighton": {
    logo: "creighton.png",
    mascots: "Bluejays",
  },
  "Dartmouth": {
    logo: "dartmouth.png",
    mascots: "Big Green",
  },
  "Davidson": {
    logo: "davidson.png",
    mascots: "Wildcats",
  },
  "Dayton": {
    logo: "dayton.png",
    mascots: "Flyers",
  },
  "DePaul": {
    logo: "depaul.png",
    mascots: "Blue Demons",
  },
  "Delaware": {
    logo: "delaware.png",
    mascots: "Blue Hens",
  },
  "Delaware State": {
    logo: "delaware_state.png",
    mascots: "Hornets",
  },
  "Denver": {
    logo: "denver.png",
    mascots: "Pioneers",
  },
  "Detroit Mercy": {
    logo: "detroit_mercy.png",
    mascots: "Titans",
  },
  "Doane": {
    logo: "doane.png",
    mascots: "Tigers",
  },
  "Drake": {
    logo: "drake.png",
    mascots: "Bulldogs",
  },
  "Drexel": {
    logo: "drexel.png",
    mascots: "Dragons",
  },
  "Duke": {
    logo: "duke.png",
    mascots: "Blue Devils",
  },
  "Duquesne": {
    logo: "duquesne.png",
    mascots: "Dukes",
  },
  "ECU": {
    logo: "east_carolina.png",
    mascots: "Pirates",
  },
  "EKU": {
    logo: "eastern_kentucky.png",
    mascots: "Colonels",
  },
  "ETSU": {
    logo: "east_tennessee_state.png",
    mascots: "Buccaneers",
  },
  "Eastern Illinois": {
    logo: "eastern_illinois.png",
    mascots: "Panthers",
  },
  "Eastern Michigan": {
    logo: "eastern_michigan.png",
    mascots: "Eagles",
  },
  "Eastern Washington": {
    logo: "eastern_washington.png",
    mascots: "Eagles",
  },
  "Elon": {
    logo: "elon.png",
    mascots: "Phoenix",
  },
  "Evansville": {
    logo: "evansville.png",
    mascots: "Purple Aces",
  },
  "FIU": {
    logo: "florida_international.png",
    mascots: "Panthers",
  },
  "Fairfield": {
    logo: "fairfield.png",
    mascots: "Stags",
  },
  "Fairleigh Dickinson": {
    logo: "fairleigh_dickinson.png",
    mascots: "Knights",
    alternate_names: ["FDU"],
  },
  "Ferris State": {
    logo: "ferris_state.png",
    mascots: "Bulldogs",
  },
  "Florida": {
    logo: "florida.png",
    mascots: "Gators",
  },
  "Florida A&M": {
    logo: "florida_a_m.png",
    mascots: "Panthers",
  },
  "Florida Atlantic": {
    logo: "florida.png",
    mascots: "Owls",
  },
  "Florida Gulf Coast": {
    logo: "florida_gulf_coast.png",
    mascots: "Eagles",
  },
  "Florida State": {
    logo: "florida_state.png",
    mascots: "Seminoles",
  },
  "Fordham": {
    logo: "fordham.png",
    mascots: "Rams",
  },
  "Fresno State": {
    logo: "fresno_state.png",
    mascots: "Bulldogs",
  },
  "Furman": {
    logo: "furman.png",
    mascots: "Paladins",
  },
  "GVSU": {
    logo: "grand_valley_state.png",
    mascots: "Lakers",
    alternate_names: ["Grand Valley State"],
  },
  "Gardner-Webb": {
    logo: "gardner_webb.png",
    mascots: "Bulldogs",
  },
  "George Mason": {
    logo: "george_mason.png",
    mascots: "Patriots",
  },
  "George Washington": {
    logo: "george_washington.png",
    mascots: "Colonials",
  },
  "Georgetown": {
    logo: "georgetown.png",
    mascots: "Hoyas",
  },
  "Georgia": {
    logo: "georgia.png",
    mascots: "Bulldogs",
  },
  "Georgia Southern": {
    logo: "georgia_southern.png",
    mascots: "Eagles",
  },
  "Georgia State": {
    logo: "georgia_state.png",
    mascots: "Panthers",
  },
  "Georgia Tech": {
    logo: "georgia_tech.png",
    mascots: "Yellow Jackets",
  },
  "Gonzaga": {
    logo: "gonzaga.png",
    mascots: "Bulldogs",
  },
  "Grambling State": {
    logo: "grambling_state.png",
    mascots: "Tigers",
  },
  "Grand Canyon": {
    logo: "grand_canyon.png",
    mascots: "Antelopes",
  },
  "Hampton": {
    logo: "hampton.png",
    mascots: "Pirates",
  },
  "Hartford": {
    logo: "hartford.png",
    mascots: "Hawks",
  },
  "Harvard": {
    logo: "harvard.png",
    mascots: "Crimson",
  },
  "Hawaii": {
    logo: "hawaii.png",
    mascots: "Warriors",
  },
  "High Point": {
    logo: "high_point.png",
    mascots: "Panthers",
  },
  "Hofstra": {
    logo: "hofstra.png",
    mascots: "Pride",
  },
  "Holy Cross": {
    logo: "holy_cross.png",
    mascots: "Crusaders",
  },
  "Houston": {
    logo: "houston.png",
    mascots: "Cougars",
  },
  "Houston Christian": {
    logo: "houston_christian.png",
    mascots: "Huskies",
  },
  "Howard": {
    logo: "howard.png",
    mascots: "Bison",
  },
  "IUPUI": {
    logo: "iupui.png",
    mascots: "Jaguars",
  },
  "Idaho": {
    logo: "idaho.png",
    mascots: "Vandals",
  },
  "Idaho State": {
    logo: "idaho.png",
    mascots: "Bengals",
  },
  "Illinois": {
    logo: "illinois.png",
    mascots: "Fighting Illini",
  },
  "Illinois State": {
    logo: "illinois_state.png",
    mascots: "Redbirds",
  },
  "Illinois-Chicago": {
    logo: "illinois_chicago.png",
    mascots: "Flames",
  },
  "Incarnate Word": {
    logo: "incarnate_word.png",
    mascots: "Cardinals",
  },
  "Indiana": {
    logo: "indiana.png",
    mascots: "Hoosiers",
  },
  "Indiana State": {
    logo: "indiana_state.png",
    mascots: "Sycamores",
  },
  "Iona": {
    logo: "iona.png",
    mascots: "Gaels",
  },
  "Iowa": {
    logo: "iowa.png",
    mascots: "Hawkeyes",
  },
  "Iowa State": {
    logo: "iowa_state.png",
    mascots: "Cyclones",
  },
  "Jackson State": {
    logo: "jackson_state.png",
    mascots: "Tigers",
  },
  "Jacksonville": {
    logo: "jacksonville.png",
    mascots: "Dolphins",
  },
  "Jacksonville State": {
    logo: "jacksonville_state.png",
    mascots: "Gamecocks",
  },
  "James Madison": {
    logo: "james_madison.png",
    mascots: "Dukes",
  },
  "Kansas": {
    logo: "kansas.png",
    mascots: "Jayhawks",
  },
  "Kansas City": {
    logo: "kansas_city.png",
    mascots: "Roos",
  },
  "Kansas State": {
    logo: "kansas_state.png",
    mascots: "Wildcats",
  },
  "Kennesaw State": {
    logo: "kennesaw_state.png",
    mascots: "Owls",
  },
  "Kent State": {
    logo: "kent_state.png",
    mascots: "Golden Flashes",
  },
  "Kentucky": {
    logo: "kentucky.png",
    mascots: "Wildcats",
  },
  "LIU": {
    logo: "long_island.png",
  },
  "LSU": {
    logo: "louisiana_state.png",
    mascots: "Tigers",
  },
  "La Salle": {
    logo: "la_salle.png",
    mascots: "Explorers",
  },
  "Lafayette": {
    logo: "lafayette.png",
    mascots: "Leopards",
  },
  "Lake Superior State": {
    logo: "lake_superior_state.png",
    mascots: "Lakers",
  },
  "Lamar": {
    logo: "lamar.png",
    mascots: "Cardinals",
  },
  "Le Moyne": {
    logo: "le_moyne.png",
    mascots: "Dolphins",
  },
  "Lehigh": {
    logo: "lehigh.png",
    mascots: "Hawks",
  },
  "Liberty": {
    logo: "liberty.png",
    mascots: "Flames",
  },
  "Lindenwood": {
    logo: "lindenwood.png",
    mascots: "Lions",
  },
  "Lipscomb": {
    logo: "lipscomb.png",
    mascots: "Bisons",
  },
  "Little Rock": {
    logo: "little_rock.png",
    mascots: "Trojans",
  },
  "Long Beach State": {
    logo: "long_beach_state.png",
    mascots: "49ers",
  },
  "Long Island": {
    logo: "long_island.png",
    mascots: "Sharks",
  },
  "Longwood": {
    logo: "longwood.png",
    mascots: "Lancers",
  },
  "Louisiana": {
    logo: "louisiana.png",
    mascots: "Rajin' Cajuns",
  },
  "Louisiana Tech": {
    logo: "louisiana_tech.png",
    mascots: "Bulldogs",
  },
  "Louisiana-Monroe": {
    logo: "louisiana_monroe.png",
    mascots: "Warhawks",
  },
  "Louisville": {
    logo: "louisville.png",
    mascots: "Cardinals",
  },
  "Loyola": {
    logo: "loyola.png",
    mascots: "Ramblers",
  },
  "Loyola Marymount": {
    logo: "loyola_marymount.png",
    mascots: "Lions",
  },
  "Loyola-Maryland": {
    logo: "loyola_maryland.png",
    mascots: "Greyhounds",
  },
  "MVSU": {
    logo: "mississippi_valley_state.png",
    mascots: "Delta Devils",
  },
  "Maine": {
    logo: "maine.png",
    mascots: "Black Bears",
  },
  "Manhattan": {
    logo: "manhattan.png",
    mascots: "Jaspers",
  },
  "Marist": {
    logo: "marist.png",
    mascots: "Red Foxes",
  },
  "Marquette": {
    logo: "marquette.png",
    mascots: "Golden Eagles",
  },
  "Marshall": {
    logo: "marshall.png",
    mascots: "Thundering Herd",
  },
  "Maryland": {
    logo: "maryland.png",
    mascots: "Terrapins",
  },
  "Maryland-Eastern Shore": {
    logo: "maryland_eastern_shore.png",
    mascots: "Hawks",
  },
  "Massachusetts": {
    logo: "massachusetts.png",
    mascots: "Minutemen",
  },
  "McNeese State": {
    logo: "mcneese_state.png",
    mascots: "Cowboys",
  },
  "Memphis": {
    logo: "memphis.png",
    mascots: "Tigers",
  },
  "Mercer": {
    logo: "mercer.png",
    mascots: "Bears",
  },
  "Mercyhurst": {
    logo: "mercyhurst.png",
    mascots: "Lakers",
  },
  "Merrimack": {
    logo: "merrimack.png",
    mascots: "Warriors",
  },
  "Miami": {
    logo: "miami.png",
    mascots: "Hurricanes",
  },
  "Miami OH": {
    logo: "miami_ohio.png",
    mascots: "Redhawks",
  },
  "Michigan": {
    logo: "michigan.png",
    mascots: "wolverines.png",
  },
  "Michigan State": {
    logo: "michigan_state.png",
    mascots: "Spartans",
    alternate_names: ["Michigan St."],
  },
  "Michigan Tech": {
    logo: "michigan_tech.png",
    mascots: "Huskies",
  },
  "Middle Tennessee": {
    logo: "middle_tennessee.png",
    mascots: "Blue Raiders",
  },
  "Minnesota": {
    logo: "minnesota.png",
    mascots: "Golden Gophers",
  },
  "Minnesota State": {
    logo: "minnesota_state.png",
    mascots: "Mavericks",
  },
  "Minnesota-Duluth": {
    logo: "minnesota_duluth.png",
    mascots: "Bulldogs",
  },
  "Mississippi State": {
    logo: "mississippi_state.png",
    mascots: "Bulldogs",
    alternate_names: ["Mississippi St."],
  },
  "Missouri": {
    logo: "missouri.png",
    mascots: "Tigers",
  },
  "Missouri KC": {
    logo: "missouri_kansas_city.png",
    mascots: "Roos",
  },
  "Missouri State": {
    logo: "missouri_state.png",
    mascots: "Bears",
  },
  "Monmouth": {
    logo: "monmouth.png",
    mascots: "Hawks",
  },
  "Montana": {
    logo: "montana.png",
    mascots: "Grizzlies",
  },
  "Montana State": {
    logo: "montana_state.png",
    mascots: "Bobcats",
  },
  "Morehead State": {
    logo: "morehead_state.png",
    mascots: "Eagles",
  },
  "Morgan State": {
    logo: "morgan_state.png",
    mascots: "Bears",
  },
  "Mount St. Mary's": {
    logo: "mount_st_marys.png",
    mascots: "Mountaineers",
  },
  "Murray State": {
    logo: "murray_state.png",
    mascots: "Racers",
    alternate_names: ["Murray St."],
  },
  "NC A&T": {
    logo: "north_carolina_agriculture_tech.png",
    mascots: "Aggies",
  },
  "NC Central": {
    logo: "north_carolina_central.png",
    mascots: "Eagles",
  },
  "NC State": {
    logo: "north_carolina_state.png",
    mascots: "Wolfpack",
  },
  "NIU": {
    logo: "northern_illinois.png",
    mascots: "Huskies",
  },
  "NJIT": {
    logo: "new_jersey_technology.png",
    mascots: "Highlanders",
  },
  "Navy": {
    logo: "navy.png",
    mascots: "Midshipmen",
  },
  "Nebraska": {
    logo: "nebraska.png",
    mascots: "Cornhuskers",
  },
  "Nevada": {
    logo: "nevada.png",
    mascots: "Wolf Pack",
  },
  "New Hampshire": {
    logo: "new_hampshire.png",
    mascots: "Wildcats",
  },
  "New Mexico": {
    logo: "new_mexico.png",
    mascots: "Lobos",
  },
  "New Mexico State": {
    logo: "new_mexico_state.png",
    mascots: "Aggies",
  },
  "New Orleans": {
    logo: "new_orleans.png",
    mascots: "Privateers",
  },
  "Niagara": {
    logo: "niagara.png",
    mascots: "Purple Eagles",
  },
  "Nicholls State": {
    logo: "nicholls_state.png",
    mascots: "Colonels",
  },
  "Norfolk State": {
    logo: "norfolk_state.png",
    mascots: "Spartans",
  },
  "North Alabama": {
    logo: "north_alabama.png",
    mascots: "Lions",
  },
  "North Carolina": {
    logo: "north_carolina.png",
    mascots: "Tar Heels",
  },
  "North Dakota": {
    logo: "north_dakota.png",
    mascots: "Fighting Hawks",
  },
  "North Dakota State": {
    logo: "north_dakota_state.png",
    mascots: "Bison",
  },
  "North Florida": {
    logo: "north_florida.png",
    mascots: "Ospreys",
  },
  "North Texas": {
    logo: "north_texas.png",
    mascots: "Mean Green",
  },
  "Northeastern": {
    logo: "northeastern.png",
    mascots: "Huskies",
  },
  "Northern Arizona": {
    logo: "northern_arizona.png",
    mascots: "Lumberjacks",
  },
  "Northern Colorado": {
    logo: "northern_colorado.png",
    mascots: "Bears",
  },
  "Northern Iowa": {
    logo: "northern_iowa.png",
    mascots: "Panthers",
  },
  "Northern Kentucky": {
    logo: "northern_kentucky.png",
    mascots: "Norse",
  },
  "Northern Michigan": {
    logo: "northern_michigan.png",
    mascots: "Wildcats",
  },
  "Northwestern": {
    logo: "northwestern.png",
    mascots: "Wildcats",
  },
  "Northwestern State": {
    logo: "northwestern_state.png",
    mascots: "Demons",
  },
  "Notre Dame": {
    logo: "notre_dame.png",
    mascots: "Fighting Irish",
  },
  "Oakland": {
    logo: "oakland.png",
    mascots: "Golden Grizzlies",
  },
  "Ohio": {
    logo: "ohio.png",
    mascots: "Bobcats",
  },
  "Ohio State": {
    logo: "ohio_state.png",
    mascots: "Buckeyes",
    alternate_names: ["Ohio St."],
  },
  "Oklahoma": {
    logo: "oklahoma.png",
    mascots: "Sooners",
  },
  "Oklahoma State": {
    logo: "oklahoma_state.png",
    mascots: "Cowboys",
  },
  "Old Dominion": {
    logo: "old_dominion.png",
    mascots: "Monarchs",
  },
  "Ole Miss": {
    logo: "mississippi.png",
    mascots: "Rebels",
  },
  "Omaha": {
    logo: "omaha.png",
    mascots: "Mavericks",
  },
  "Oral Roberts": {
    logo: "oral_roberts.png",
    mascots: "Golden Eagles",
  },
  "Oregon": {
    logo: "oregon.png",
    mascots: "Ducks",
  },
  "Oregon State": {
    logo: "oregon_state.png",
    mascots: "Beavers",
  },
  "Pacific": {
    logo: "pacific.png",
    mascots: "Tigers",
  },
  "Penn": {
    logo: "pennsylvania.png",
    mascots: "Quakers",
  },
  "Penn State": {
    logo: "pennsylvania_state.png",
    mascots: "Nittany Lions",
  },
  "Pepperdine": {
    logo: "pepperdine.png",
    mascots: "Waves",
  },
  "Pittsburgh": {
    logo: "pittsburgh.png",
    mascots: "Panthers",
  },
  "Portland": {
    logo: "portland.png",
    mascots: "Pilots",
  },
  "Portland State": {
    logo: "portland_state.png",
    mascots: "Vikings",
  },
  "Prairie View A&M": {
    logo: "prairie_view_a_m.png",
    mascots: "Panthers",
  },
  "Presbyterian": {
    logo: "presbyterian.png",
    mascots: "Blue Hose",
  },
  "Princeton": {
    logo: "princeton.png",
    mascots: "Tigers",
  },
  "Providence": {
    logo: "providence.png",
    mascots: "Friars",
  },
  "Purdue": {
    logo: "purdue.png",
    mascots: "Boilermakers",
  },
  "Purdue Fort Wayne": {
    logo: "purdue_fort_wayne.png",
    mascots: "Mastodons",
  },
  "Queens": {
    logo: "queens.png",
    mascots: "Royals",
  },
  "Quinnipiac": {
    logo: "quinnipiac.png",
    mascots: "Bobcats",
  },
  "RIT": {
    logo: "rochester_technology.png",
    mascots: "Tigers",
  },
  "RPI": {
    logo: "rensseleaer_polytechnic.png",
    mascots: "Engineers",
  },
  "Radford": {
    logo: "radford.png",
    mascots: "Highlanders",
  },
  "Rhode Island": {
    logo: "rhode_island.png",
    mascots: "Rams",
  },
  "Rice": {
    logo: "rice.png",
    mascots: "Owls",
  },
  "Richmond": {
    logo: "richmond.png",
    mascots: "Spiders",
  },
  "Rider": {
    logo: "rider.png",
    mascots: "Broncs",
  },
  "Robert Morris": {
    logo: "robert_morris.png",
    mascots: "Colonials",
  },
  "Rutgers": {
    logo: "rutgers.png",
    mascots: "Scarlet Knights",
  },
  "SE Louisiana": {
    logo: "southeastern_louisiana.png",
    mascots: "Lions",
    alternate_names: ["Southeastern Louisiana"],
  },
  "SEMO": {
    logo: "southeast_missouri_state.png",
    mascots: "Redhawks",
  },
  "SIUE": {
    logo: "southern_illinois_edwardsville.png",
    mascots: "Cougars",
  },
  "SMU": {
    logo: "southern_methodist.png",
    mascots: "Mustangs",
  },
  "Sacramento State": {
    logo: "sacramento_state.png",
    mascots: "Hornets",
  },
  "Sacred Heart": {
    logo: "sacred_heart.png",
    mascots: "Pioneers",
  },
  "Saint Francis": {
    logo: "saint_francis.png",
    mascots: "Red Flash",
  },
  "Saint Louis": {
    logo: "saint_louis.png",
    mascots: "Billikens",
  },
  "Saint Mary's": {
    logo: "saint_marys.png",
    mascots: "Gaels",
    alternate_names: ["Saint-Mary's"],
  },
  "Saint Peter's": {
    logo: "saint_peters.png",
    mascots: "Peacocks",
  },
  "Sam Houston State": {
    logo: "sam_houston_state.png",
    mascots: "Bearkats",
    alternate_names: ["Sam Houston"],
  },
  "Samford": {
    logo: "samford.png",
    mascots: "Bulldogs",
  },
  "San Diego": {
    logo: "san_diego.png",
    mascots: "Toreros",
  },
  "San Diego State": {
    logo: "san_diego_state.png",
    mascots: "Aztecs",
  },
  "San Francisco": {
    logo: "san_francisco.png",
    mascots: "Dons",
  },
  "San Jose State": {
    logo: "san_jose_state.png",
    mascots: "Spartans",
    alternate_names: ["San Jose St."],
  },
  "Santa Clara": {
    logo: "santa_clara.png",
    mascots: "Broncos",
  },
  "Savannah State": {
    logo: "savannah_state.png",
    mascots: "Tigers",
  },
  "Seattle": {
    logo: "seattle.png",
    mascots: "Redhawks",
  },
  "Seton Hall": {
    logo: "seton_hall.png",
    mascots: "Pirates",
  },
  "Siena": {
    logo: "siena.png",
    mascots: "Saints",
  },
  "South Alabama": {
    logo: "south_alabama.png",
    mascots: "Jaguars",
  },
  "South Carolina": {
    logo: "south_carolina.png",
    mascots: "Gamecocks",
  },
  "South Carolina State": {
    logo: "south_carolina_state.png",
    mascots: "Bulldogs",
  },
  "South Dakota": {
    logo: "south_dakota.png",
    mascots: "Coyotes",
  },
  "South Dakota State": {
    logo: "south_dakota_state.png",
    mascots: "Jackrabbits",
  },
  "South Florida": {
    logo: "south_florida.png",
    mascots: "Bulls",
  },
  "Southern": {
    logo: "southern.png",
    mascots: "Jaguars",
    alternate_names: ["Southern U."],
  },
  "Southern Illinois": {
    logo: "southern_illinois.png",
    mascots: "Salukis",
  },
  "Southern Indiana": {
    logo: "southern_indiana.png",
    mascots: "Screaming Eagles",
  },
  "Southern Miss": {
    logo: "southern_mississippi.png",
    mascots: "Golden Eagles",
    alternate_names: ["Southern Miss."],
  },
  "Southern Utah": {
    logo: "southern_utah.png",
    mascots: "Thunderbolts",
  },
  "St. Bonaventure": {
    logo: "saint_bonaventure.png",
    mascots: "Bonnies",
  },
  "St. Cloud State": {
    logo: "saint_cloud_state.png",
    mascots: "Huskies",
  },
  "St. John's": {
    logo: "saint_johns.png",
    mascots: "Red Storm",
  },
  "St. Joseph's": {
    logo: "saint_josephs.png",
    mascots: "Hawks",
  },
  "St. Thomas": {
    logo: "saint_thomas.png",
    mascots: "Tommies",
  },
  "Stanford": {
    logo: "stanford.png",
    mascots: "Cardinal",
  },
  "Stephen F. Austin": {
    logo: "stephen_f_austin.png",
    mascots: "Lumberjacks",
  },
  "Stetson": {
    logo: "stetson.png",
    mascots: "Hatters",
  },
  "Stonehill": {
    logo: "stonehill.png",
    mascots: "Skyhawks",
  },
  "Stony Brook": {
    logo: "stony_brook.png",
    mascots: "Seawolves",
  },
  "Syracuse": {
    logo: "syracuse.png",
    mascots: "Orange",
  },
  "TCU": {
    logo: "texas_christian.png",
    mascots: "Horned Frogs",
  },
  "Tarleton": {
    logo: "tarleton.png",
    mascots: "Texans",
  },
  "Temple": {
    logo: "temple.png",
    mascots: "Owls",
  },
  "Tennessee": {
    logo: "tennessee.png",
    mascots: "Volunteers",
  },
  "Tennessee State": {
    logo: "tennessee_state.png",
    mascots: "Tigers",
  },
  "Tennessee Tech": {
    logo: "tennessee_tech.png",
    mascots: "Golden Eagles",
  },
  "Texas": {
    logo: "texas.png",
    mascots: "Longhorns",
  },
  "Texas - San Antonio": {
    logo: "texas_san_antonio.png",
    mascots: "Roadrunners",
  },
  "Texas A&M": {
    logo: "texas_a_m.png",
    mascots: "Aggies",
  },
  "Texas A&M - CC": {
    logo: "texas_a_m_corpus_christi.png",
    mascots: "Islanders",
    alternate_names: ["Texas A&M - Corpus Christi"],
  },
  "Texas Southern": {
    logo: "texas_southern.png",
    mascots: "Tigers",
  },
  "Texas State": {
    logo: "texas_state.png",
    mascots: "Bobcats",
  },
  "Texas Tech": {
    logo: "texas_tech.png",
    mascots: "Red Raiders",
  },
  "The Citadel": {
    logo: "citadel.png",
    mascots: "Bulldogs",
  },
  "Toledo": {
    logo: "toledo.png",
    mascots: "Rockets",
  },
  "Towson": {
    logo: "towson.png",
    mascots: "Tigers",
  },
  "Troy": {
    logo: "troy.png",
    mascots: "Trojans",
  },
  "Tulane": {
    logo: "tulane.png",
    mascots: "Green Wave",
  },
  "Tulsa": {
    logo: "tulsa.png",
    mascots: "Golden Hurricane",
  },
  "UAB": {
    logo: "alabama_birmingham.png",
    mascots: "Blazers",
  },
  "UC Davis": {
    logo: "california_davis.png",
    mascots: "Aggies",
  },
  "UC Irvine": {
    logo: "california_irvine.png",
    mascots: "Anteaters",
  },
  "UC Riverside": {
    logo: "california_riverside.png",
    mascots: "Highlanders",
  },
  "UC San Diego": {
    logo: "california_san_diego.png",
    mascots: "Tritons",
  },
  "UCF": {
    logo: "central_florida.png",
    mascots: "Knights",
  },
  "UCLA": {
    logo: "california_los_angeles.png",
    mascots: "Bruins",
  },
  "UCSB": {
    logo: "california_santa_barbara.png",
    mascots: "Gauchos",
  },
  "UConn": {
    logo: "connecticut.png",
    mascots: "Huskies",
  },
  "UIC": {
    logo: "illinois_chicago.png",
  },
  "UMBC": {
    logo: "maryland_baltimore_county.png",
    mascots: "Retrievers",
  },
  "UMass Lowell": {
    logo: "massachusetts_lowell.png",
    mascots: "River Hawks",
  },
  "UNC Asheville": {
    logo: "north_carolina_asheville.png",
    mascots: "Bulldogs",
  },
  "UNC Greensboro": {
    logo: "north_carolina_greensboro.png",
    mascots: "Spartans",
  },
  "UNC Wilmington": {
    logo: "north_carolina_wilmington.png",
  },
  "UNLV": {
    logo: "nevada_las_vegas.png",
    mascots: "Rebels",
  },
  "USC": {
    logo: "southern_california.png",
    mascots: "Trojans",
  },
  "USC Upstate": {
    logo: "south_carolina_upstate.png",
    mascots: "Spartans",
  },
  "UT Arlington": {
    logo: "texas_arlington.png",
    mascots: "Mavericks",
  },
  "UT Martin": {
    logo: "tennessee_martin.png",
    mascots: "Skyhawks",
  },
  "UTEP": {
    logo: "texas_el_paso.png",
    mascots: "Miners",
  },
  "UTPB": {
    logo: "texas_permian_basin.png",
    mascots: "Falcons",
  },
  "UTRGV": {
    logo: "texas_rio_grande_valley.png",
    mascots: "Vaqueros",
    alternate_names: ["Texas Rio Grande Valley"],
  },
  "Union": {
    logo: "union.png",
    mascots: "Dutchmen",
  },
  "Utah": {
    logo: "utah.png",
    mascots: "Utes",
  },
  "Utah State": {
    logo: "utah_state.png",
    mascots: "Aggies",
  },
  "Utah Tech": {
    logo: "utah_tech.png",
    mascots: "Trailblazers",
  },
  "Utah Valley": {
    logo: "utah_valley.png",
    mascots: "Wolverines",
  },
  "VCU": {
    logo: "virginia_commonwealth.png",
    mascots: "Rams",
  },
  "VMI": {
    logo: "virginia_military.png",
    mascots: "Keydets",
  },
  "Valparaiso": {
    logo: "valparaiso.png",
    mascots: "Beacons",
  },
  "Vanderbilt": {
    logo: "vanderbilt.png",
    mascots: "Commodores",
  },
  "Vermont": {
    logo: "vermont.png",
    mascots: "Catamounts",
  },
  "Villanova": {
    logo: "villanova.png",
    mascots: "Wildcats",
  },
  "Virginia": {
    logo: "virginia.png",
    mascots: "Cavaliers",
  },
  "Virginia Tech": {
    logo: "virginia_tech.png",
    mascots: "Hokies",
  },
  "WKU": {
    logo: "western_kentucky.png",
    mascots: "Hilltoppers",
  },
  "Wagner": {
    logo: "wagner.png",
    mascots: "Seahawks",
  },
  "Wake Forest": {
    logo: "wake_forest.png",
    mascots: "Demon Deacons",
  },
  "Washington": {
    logo: "washington.png",
    mascots: "Huskies",
  },
  "Washington State": {
    logo: "washington_state.png",
    mascots: "Cougars",
  },
  "Weber State": {
    logo: "weber_state.png",
    mascots: "Wildcats",
  },
  "West Virginia": {
    logo: "west_virginia.png",
    mascots: "Mountaineers",
  },
  "Western Carolina": {
    logo: "western_carolina.png",
    mascots: "Catamounts",
  },
  "Western Illinois": {
    logo: "western_illinois.png",
    mascots: "Leathernecks",
  },
  "Western Michigan": {
    logo: "western_michigan.png",
    mascots: "Broncos",
  },
  "Wichita State": {
    logo: "wichita_state.png",
    mascots: "Shockers",
  },
  "William & Mary": {
    logo: "william_mary.png",
    mascots: "Tribe",
  },
  "Winthrop": {
    logo: "winthrop.png",
    mascots: "Eagles",
  },
  "Wisconsin": {
    logo: "wisconsin.png",
    mascots: "Badgers",
  },
  "Wisconsin - Green Bay": {
    logo: "wisconsin_green_bay.png",
    mascots: "Phoenix",
  },
  "Wisconsin - Milwaukee": {
    logo: "wisconsin_milwaukee.png",
    mascots: "Panthers",
  },
  "Wofford": {
    logo: "wofford.png",
    mascots: "Terriers",
  },
  "Wright State": {
    logo: "wright_state.png",
    mascots: "Raiders",
  },
  "Wyoming": {
    logo: "wyoming.png",
    mascots: "Cowboys",
  },
  "Xavier": {
    logo: "xavier.png",
    mascots: "Musketeers",
  },
  "Yale": {
    logo: "yale.png",
    mascots: "Bulldogs",
  },
  "Youngstown State": {
    logo: "youngstown_state.png",
    mascots: "Penguins",
  },
}
