import React, { useState, useEffect } from "react";
import { Game, ScheduleItem, SeasonData, SeasonSportData, Sport } from "@/types";

function CrapsSelector({ letter, icon, onClick = () => null, selected = false }: {
  letter: string,
  icon: string,
  onClick: () => void,
  selected: boolean
}) {
  return (
    <div onClick={() => onClick()} className={`col-2 craps-selector ${selected ? 'selected' : ''}`}>
      <div className="top">{letter}</div>
      <i className={`fas fa-${icon}-ball`}></i>
    </div>
  )
}

export default function SeasonTray() {
  const [sport, setSport] = useState<Sport>((window.localStorage.getItem('season-tray-sport') ?? 'football') as Sport);
  const [gameData, setGameData] = useState<SeasonData>({});

  const sportGameData: SeasonSportData = gameData[sport] ?? { displayString: '', games: [], events: [], standings: [] };
  const games = sportGameData.games;
  const events = sportGameData.events;
  const schedule: ScheduleItem[] = (games as ScheduleItem[]).concat(events as ScheduleItem[]).sort((itemA, itemB) => {
    if (itemA.start_time == itemB.start_time) {
      return 0;
    } else if (itemA.start_time > itemB.start_time) {
      return 1;
    } else {
      return -1;
    }
  })
  const standings = sportGameData.standings ?? null;
  const zone_string = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  schedule.forEach(scheduleItem => {
    if (scheduleItem.type === 'event' && scheduleItem.end_time !== null) {
      scheduleItem.day_string = new Intl.DateTimeFormat('en-US', { timeZone: 'America/Chicago', month: "short", day: "numeric" }).format(new Date(scheduleItem.start_time * 1000)).replace(/ /g, '\xa0') + ' - ' + new Intl.DateTimeFormat('en-US', { timeZone: 'America/Chicago', month: "short", day: "numeric" }).format(new Date(scheduleItem.end_time * 1000)).replace(/ /g, '\xa0');
    } else if (scheduleItem.time_tba) {
      scheduleItem.day_string = new Intl.DateTimeFormat('en-US', { timeZone: 'America/Chicago', month: "short", day: "numeric" }).format(new Date(scheduleItem.start_time * 1000)).replace(/ /g, '\xa0');
      scheduleItem.time_string = 'TBA';
    } else {
      scheduleItem.day_string = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, month: "short", day: "numeric" }).format(new Date(scheduleItem.start_time * 1000)).replace(/ /g, '\xa0');
      scheduleItem.time_string = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, hour: "numeric", minute: "2-digit" }).format(new Date(scheduleItem.start_time * 1000)).replace(/ /g, '\xa0');
    }

    if (scheduleItem.postponed) {
      scheduleItem.time_string = '';
      scheduleItem.tv_channel = 'Postponed';
    }
  });

  useEffect(() => {
    const getData = async () => {
      const response = await fetch('/all_sports/season/current.json');
      setGameData(await response.json());
    };

    getData();
  }, []);

  return (
    <div className="tray">
      <div className="inner-tray">
        <div className="container-fluid" style={{ marginBottom: "0.75em" }}>
          <div className="row">
            <CrapsSelector
              letter="F"
              icon="football"
              selected={sport === 'football'}
              onClick={() => { setSport('football'); window.localStorage.setItem('season-tray-sport', 'football') }}
            />
            <CrapsSelector
              letter="V"
              icon="volleyball"
              selected={sport === 'volleyball'}
              onClick={() => { setSport('volleyball'); window.localStorage.setItem('season-tray-sport', 'volleyball') }}
            />
            <CrapsSelector
              letter="M"
              icon="basketball"
              selected={sport === 'mens_basketball'}
              onClick={() => { setSport('mens_basketball'); window.localStorage.setItem('season-tray-sport', 'mens_basketball') }}
            />
            <CrapsSelector
              letter="W"
              icon="basketball"
              selected={sport === 'womens_basketball'}
              onClick={() => { setSport('womens_basketball'); window.localStorage.setItem('season-tray-sport', 'womens_basketball') }}
            />
            <CrapsSelector
              letter="B"
              icon="baseball"
              selected={sport === 'baseball'}
              onClick={() => { setSport('baseball'); window.localStorage.setItem('season-tray-sport', 'baseball') }}
            />
            <CrapsSelector
              letter="S"
              icon="baseball"
              selected={sport === 'softball'}
              onClick={() => { setSport('softball'); window.localStorage.setItem('season-tray-sport', 'softball') }}
            />
          </div>
        </div>
        <h1>{sportGameData.displayString}</h1>
        {schedule.length != 0 && (
          <>
            <div className="schedule-layout">
              <h2>Schedule</h2>
              <div>
                <table>
                  <tbody>
                    {schedule.map(game => {
                      if (game.type === 'game') {
                        return (
                          <tr key={game.id}>
                            <td style={{ textAlign: 'center', verticalAlign: 'top' }}>{game.vs_string.split(' ')[0]}</td>
                            <td>{game.vs_string.split(' ').slice(1).join(' ')}</td>
                            <td>
                              {game.day_string}
                            </td>
                            {game.state === 'finished' ? (
                              <td className={'bold'} style={{ textAlign: 'right', paddingLeft: '0.5em', fontVariantNumeric: 'tabular-nums' }} >
                                {game.result.replace(/ /g, '\xa0')}
                              </td>
                            ) : game.state === 'in_progress' ? (
                              <td style={{ textAlign: 'right', paddingLeft: '0.5em'}}>
                                {game.tv_channel != undefined ? (`${game.tv_channel}\xa0\u2011\xa0`) : ''}{"Live"}
                              </td>
                            ) : (
                              <td style={{ textAlign: 'right', fontVariantNumeric: 'tabular-nums', paddingLeft: '0.5em' }}>
                                {game.tv_channel != undefined ? (`${game.tv_channel}\xa0\u2011\xa0`) : ''}{game.time_string}
                              </td>
                            )}
                          </tr>
                        );
                      } else {
                        return (
                          <tr style={{ height: "2.4em" }} key={game.id}>
                            <td style={{ textAlign: 'center' }}>&#x25cf;</td>
                            <td>{game.name}</td>
                            <td>
                              {game.day_string}
                            </td>
                            <td style={{ textAlign: 'right', paddingLeft: '0.5em', fontVariantNumeric: 'tabular-nums' }}>
                              {game.tv_channel != undefined ? (game.tv_channel + (game.time_string === undefined ? '' : "\xa0\u2011\xa0")) : ''}{game.time_string}
                            </td>
                          </tr>
                        )
                      }
                    }
                    )}
                  </tbody>
                </table>
                <div id="timezone-explanation">
                  All times are shown in <strong>{zone_string}</strong> timezone.
                </div>
              </div>
            </div>
            <hr />
          </>
        )}
        {standings && (
          <div className="standings-layout">
            <h2>Big Ten Standings</h2>
            <div>
              <table>
                <thead style={{ borderBottom: '1px solid #FEFDFA' }}>
                  <tr>
                    <th className="bold">Team</th>
                    <th className="bold" style={{ textAlign: 'right' }}>Conf</th>
                    {(sport === 'baseball' || sport === 'softball') && (
                      <th className="bold" style={{ textAlign: 'right' }}>GB</th>
                    )}
                    <th className="bold" style={{ textAlign: 'right' }}>Overall</th>
                  </tr>
                </thead>
                <tbody>
                  {standings.map((standing) => {
                    return <tr key={`standing-${standing[0]}`}>
                        <td className={standing[0] === 'Nebraska' ? 'bold' : ''}>
                        {standing[0]}
                      </td>
                      <td className={standing[0] === 'Nebraska' ? 'bold' : ''} style={{ textAlign: 'right', fontVariantNumeric: 'tabular-nums' }}>
                        {standing[1]}
                      </td>
                      {(sport === 'baseball' || sport === 'softball') && (
                        <td className={standing[0] === 'Nebraska' ? 'bold' : ''} style={{ textAlign: 'right', fontVariantNumeric: 'tabular-nums' }}>
                          {standing[3]}
                        </td>
                      )}
                      <td className={standing[0] === 'Nebraska' ? 'bold' : ''} style={{ textAlign: 'right', fontVariantNumeric: 'tabular-nums' }}>
                        {standing[2]}
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}