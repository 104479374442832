import React, { useState, useEffect } from "react";

type ScoreTableData = {
  away_team: string,
  home_team: string,
  away: (number | null)[],
  home: (number | null)[],
  periods: number[],
  overtime_periods: number[],
  period_word: string,
  overtime_period_word: string,
  overtime_applies: boolean
}

export default function ScoreTable({ scoresJsonLink }: { scoresJsonLink: string }) {
  const [scoreTableData, setScoreTableData] = useState<ScoreTableData | null>(null);
  const [awayScores, setAwayScores] = useState<(number | null)[]>([]);
  const [homeScores, setHomeScores] = useState<(number | null)[]>([])

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(scoresJsonLink);
      const data = await response.json();
      setScoreTableData(data);
      setAwayScores(data.away);
      setHomeScores(data.home);
    };

    getData();
  }, []);

  if (scoreTableData === null) {
    return null;
  }

  const {
    away_team, 
    home_team,
    periods,
    period_word,
    overtime_period_word,
    overtime_applies
  } = scoreTableData;

  const overtime_periods: number[] = [];
  let endFound = false;
  while (!endFound) {
    if (awayScores[periods.length + overtime_periods.length] !== undefined || homeScores[periods.length + overtime_periods.length] !== undefined) {
      const nextNum = overtime_periods.length === 0 ? 1 : overtime_periods[overtime_periods.length-1] + 1
      overtime_periods.push(nextNum);
    } else {
      endFound = true
    }
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Team</th>
          {periods.map((i: number) =>
            <th key={`period-${i}`}>{period_word} {i}</th>
          )}
          {overtime_periods.map((j: number, index: number) => {
            if (awayScores[index + periods.length] !== undefined ||
              homeScores[index + periods.length] !== undefined) {
              return <th key={`ot-period-${j}`}>{overtime_period_word} {j}</th>
            }
          })}
          {overtime_applies && (
            <th>OT</th>
          )}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{away_team}</td>
          {periods.map((i: number) =>
            <td key={`away-${i}`}>
              <input
                type="number"
                className="score"
                name={"away_" + i + '_score'}
                value={awayScores[i - 1] ?? ''}
                onChange={(event) => {
                  setAwayScores(awayScores.map((val: number | null, index: number) => {
                    if (index === i - 1) {
                      return parseInt(event.target.value);
                    }
                    return val;
                  }));
                }}
              />
            </td>
          )}
          {overtime_periods.map((j: number, otIndex: number) => {
            if (awayScores[otIndex + periods.length] !== undefined ||
              homeScores[otIndex + periods.length] !== undefined) {
              return <td key={`away-ot-${otIndex}`}>
                <input
                  className="score"
                  type="number"
                  name={"away_" + (j + periods.length) + '_score'}
                  value={awayScores[otIndex + periods.length] ?? ''}
                  onChange={(event) => {
                    setAwayScores(awayScores.map((val: number | null, index: number) => {
                      if (index === otIndex + periods.length) {
                        return parseInt(event.target.value);
                      }
                      return val;
                    }));
                  }}
                />
              </td>
            }
          })}
          {overtime_applies && (
            <td>
              <button
                className="btn btn-success"
                onClick={() => {
                      let scores = [...awayScores];
                      scores.push(null);
                      setAwayScores(scores);
                      let scores2 = [...homeScores];
                      scores2.push(null)
                      setHomeScores(scores2);
                    }
                }
                type="button"
              >
                <i className="fas fa-plus"></i>
              </button>
              &nbsp;
              <button
                className="btn btn-danger"
                onClick={() => {
                      let scores = [...awayScores];
                      scores.pop();
                      setAwayScores(scores);
                      let scores2 = [...homeScores];
                      scores2.pop();
                      setHomeScores(scores2);
                }}
                type="button"
              >
                <i className="fas fa-minus"></i>
              </button>
            </td>
          )}
          <td className="total">{awayScores.reduce((acc, value) => (acc ?? 0) + (value ?? 0), 0)}</td>
        </tr>
        <tr>
          <td>{home_team}</td>
          {periods.map((i: number) =>
            <td key={`home-${i}`}>
              <input
                className="score"
                type="number"
                name={"home_" + i + '_score'}
                value={homeScores[i - 1] ?? ''}
                onChange={(event) => {
                  setHomeScores(homeScores.map((val: number | null, index: number) => {
                    if (index === i - 1) {
                      return parseInt(event.target.value);
                    }
                    return val;
                  }));
                }}
              />
            </td>
          )}
          {overtime_periods.map((j: number, otIndex: number) => {
            if (awayScores[otIndex + periods.length] !== undefined ||
              homeScores[otIndex + periods.length] !== undefined) {
              return <td key={`home-ot-${otIndex}`}>
                <input
                  className="score"
                  type="number"
                  name={"home_" + (j + periods.length) + '_score'}
                  value={homeScores[otIndex + periods.length] ?? ''}
                  onChange={(event) => {
                    setHomeScores(homeScores.map((val: number | null, index: number) => {
                      if (index === otIndex + periods.length) {
                        return parseInt(event.target.value);
                      }
                      return val;
                    }));
                  }}
                />
              </td>
            }
          })}
          {overtime_applies && (
            <td></td>
          )}
          <td className="total">{homeScores.reduce((acc, value) => (acc ?? 0) + (value ?? 0), 0)}</td>
        </tr>
      </tbody>
    </table>
  );
}