import { VolleyballGame } from "@/types";
import React, { useState, useEffect, useCallback } from "react";
import classes from './VolleyballGameControl.module.css';
import GameScorecard from "../game/GameScorecard";

export default function VolleyballGameControl({ id }: { id: number }) {
  const [game, setGame] = useState<VolleyballGame | null>(null);

  useEffect(() => {
    const loadGame = async () => {
      const response = await fetch(`/admin/volleyball/games/${id}.json`);
      setGame(await response.json());
    };

    loadGame();
  }, []);

  const startGame = useCallback(async () => {
    const response = await fetch(`/admin/volleyball/games/${id}/start.json`, { method: "POST" });
    setGame(await response.json());
  }, [])

  const updateScore = useCallback(async (away: number, home: number) => {
    const response = await fetch(`/admin/volleyball/games/${id}/update_score.json`, {
      method: "POST",
      body: JSON.stringify({
        away: away,
        home: home
      })
    });
    setGame(await response.json());
  }, []);

  if (game === null) {
    return null;
  }

  return (
    <>
      <div className="tray" style={{ marginBottom: 25 }}>
        <div className="inner-tray">
          <GameScorecard game={game} />
        </div>
      </div>
      {game.data_links.length > 0 && (
        <>
          <div className={classes.dataLinksContainer}>
            <h2>Game Data Links</h2>
            <div className={classes.dataLinks}>
              {game.data_links.map((dataLink) =>
                <>
                  <div>{dataLink.parser}</div>
                  <div className={classes.dataLinkUrl}>{dataLink.url}</div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <div className={classes.controlCenter}>
        {game.last_tracked_at !== null && (
          <div className={classes.lastTracked}>
            Last Tracked: {new Intl.DateTimeFormat('en-US', {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric", timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }).format(new Date(game.last_tracked_at * 1000))}
          </div>
        )}

        <div className={classes.scoreKeeper}>
          {game.state == 'upcoming' &&
            (
              <button
                className="btn btn-success btn-lg"
                onClick={() => { startGame(); }}
              >
                Start
              </button>
            )
          }

          {game.state === 'in_progress' && (
            <>
              <h2 className={classes.teamName}>
                {game.away_team}
              </h2>
              <div className={classes.scoreControl}>
                <div className={classes.scoreBox}>
                  {game.away_latest_period_score}
                </div>
                <div className={classes.buttons}>
                  <button
                    className={classes.upButton}
                    onClick={() => updateScore(game.away_latest_period_score + 1, game.home_latest_period_score)}
                  >
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <button
                    className={classes.downButton}
                    onClick={() => updateScore(game.away_latest_period_score - 1, game.home_latest_period_score)}
                  >
                    <i className="fas fa-arrow-down"></i>
                  </button>
                </div>
              </div>
              <div className={classes.scoreControl}>
                <div className={classes.scoreBox}>
                  {game.home_latest_period_score}
                </div>
                <div className={classes.buttons}>
                  <button
                    className={classes.upButton}
                    onClick={() => updateScore(game.away_latest_period_score, game.home_latest_period_score + 1)}
                  >
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <button
                    className={classes.downButton}
                    onClick={() => updateScore(game.away_latest_period_score, game.home_latest_period_score - 1)}
                  >
                    <i className="fas fa-arrow-down"></i>
                  </button>
                </div>
              </div>
              <h2 className={classes.teamName}>
                {game.home_team}
              </h2>
            </>
          )}

          {game.state === "finished" && (
            <h2 className={classes.teamName}>
              Game Finished
            </h2>
          )}
        </div>
      </div>
    </>
  );
}